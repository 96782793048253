import { useEffect, useState } from 'react';
import { useQuery, useMutation, WatchQueryFetchPolicy } from '@apollo/client';
import { GET_CLIENT } from '@apolloCli/queries/client';
import { UPDATE_CLIENT } from '@apolloCli/mutations/client';
import { User } from '@interfaces/User';
import { EMAILS } from '@const/godModeEmails';
import { useUser as useUserClerk } from '@clerk/clerk-react';

type Args = {
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const useUser = ({ fetchPolicy }: Args = {}) => {
  const [godmode, setGodmode] = useState(false);
  const { isSignedIn, isLoaded } = useUserClerk();

  const { data, error, loading, refetch } = useQuery<{ client: User }>(GET_CLIENT, {
    fetchPolicy,
  });

  const [updateUser, { loading: mutationLoading, error: mutationError }] = useMutation<
    { updateClient: User },
    { input: Partial<User> }
  >(UPDATE_CLIENT);

  const user = data?.client || undefined;

  useEffect(() => {
    if (user?.email && (/@alltherooms.com\s*$/.test(user?.email) || EMAILS.includes(user?.email))) {
      setGodmode(true);
    } else {
      setGodmode(false);
    }
  }, [user]);

  return {
    isLogin: isSignedIn,
    user,
    isGodmode: godmode,
    loading: loading || mutationLoading || !isLoaded,
    error: error || mutationError,
    refetch,
    updateUser,
  };
};
