import { Store as PullStateStore } from 'pullstate';
import { UpsertCompetitorGroup } from '@hooks/useCompetitorSet';
import { MarketResponse } from '@hooks/useCustomMarket';

export const STORE_ATR_SETTINGS = 'atr_settings';

const Store = new PullStateStore({
  lastCustomCompSetInStore: null as UpsertCompetitorGroup | null,
  lastCustomMarketInStore: null as MarketResponse | null,
  previousUrls: [] as string[],
});

Store.subscribe(
  (s) => s,
  (store) => {
    localStorage.setItem(STORE_ATR_SETTINGS, JSON.stringify(store));
  },
);

export const rehydrateStore = async () => {
  try {
    const atrSettings = localStorage.getItem(STORE_ATR_SETTINGS);
    if (atrSettings !== null) {
      const data = JSON.parse(atrSettings);
      Store.replace(data);
    }
  } catch (e) {
    // do nothing
  }
};

export default Store;
