import React from 'react';
import clsx from 'clsx';
import { LogoDoors } from '@components/iconography/LogoDoors/LogoDoors';

import styles from './LoadingLayer.module.scss';

type Props = {
  className?: string;
  fixed?: boolean;
  theme?: 'white' | 'black' | 'none';
  text?: string | React.ReactNode;
  loop?: boolean;
};

export const LoadingLayer: React.FC<Props> = ({ className, fixed, theme = 'black', text, loop = true }) => (
  <div
    className={clsx(
      styles.LoadingLayer,
      fixed && styles.Fixed,
      theme === 'white' && styles.WhiteTheme,
      theme === 'black' && styles.BlackTheme,
      theme === 'none' && styles.NoneTheme,
      className,
    )}
  >
    <div className={styles.LogoContainer}>
      <LogoDoors loop={loop} />
      {text}
    </div>
  </div>
);
