import { makeVar, TypePolicies } from '@apollo/client';
import { Rate } from '@hooks/useCurrency';
import { MetricId, PercentileId, FBIMetricId, FBIPercentileId, MetricIdV2 } from 'src/interfaces/Metrics';
import { GlobalNotification, NotificationItem, DataAnalyzeSection, MapCoordinates } from '@interfaces/UI';
import { getApolloClient } from '@apolloCli/apolloClient';
import { Listing } from '@apolloCli/queries/listings';
import { PropertyInfo } from '@interfaces/Properties';
import { ListingsTop } from '@hooks/useListingsTop';
import { GuidedTour } from '@components/tours/utils';

export const isLoginModalOpenVar = makeVar<boolean>(false);
export const isSideFiltersOpenVar = makeVar<boolean>(false);
export const toggleFollowAreaVar = makeVar<boolean>(false);
export const isSideMenuOpenVar = makeVar<boolean>(false);
export const isResettingFilterVar = makeVar<boolean>(false);
export const menuAlertBadgeVar = makeVar<boolean>(false);
export const openSnackVar = makeVar<boolean>(false);
export const resetDropdownVar = makeVar<boolean>(false);
export const sideMenuListsOpenVar = makeVar<boolean>(false);
export const isCurrencyModalOpenVar = makeVar<boolean>(false);
export const isMarketMenuOpenVar = makeVar<boolean>(false);
export const isSubscribeModalOpenVar = makeVar<boolean>(false);
export const missingModalOpenVar = makeVar<boolean>(false);
export const setInactiveSubsVar = makeVar<boolean>(false);
export const incompleteFutureDataVar = makeVar<boolean>(false);
export const setScrolledDownVar = makeVar<boolean>(false);
export const mapFullScreenVar = makeVar<boolean>(false);
export const showAccountFlowPopOverVar = makeVar<boolean>(false);
export const notificationPositionBottomLeftVar = makeVar<boolean>(false);
export const openRandomMapCardVar = makeVar<boolean>(false);
export const FBIdaySelectedVar = makeVar<string | null>(null);
export const childAreaStateVar = makeVar<number | null>(null);
export const globalNotificationVar = makeVar<GlobalNotification['globalNotification']>(null);
export const currencyCodeVar = makeVar<Rate['code']>('USD');
export const newCompListVar = makeVar<boolean>(false);
export const metricsVar = makeVar<MetricId[]>(['adr_booked']);
export const metricsrModalVar = makeVar<MetricId[]>(['adr_booked']);
export const metricsVarV2 = makeVar<MetricIdV2[]>(['adr']);
export const FBImetricsVar = makeVar<FBIMetricId[]>(['q_adr_booked']);
export const notificationsVar = makeVar<NotificationItem[]>([]);
export const lastActiveSinceVar = makeVar<string>('30-');
export const filterRatingTypeVar = makeVar<string>('20+');
export const showOnMapListingVar = makeVar<null | Listing | ListingsTop>(null);
export const snackBarVar = makeVar<null | { timer: number; node: React.ReactNode }>(null);
export const isMissingSomethingVar = makeVar<boolean>(false);
export const currentMapCoordinates = makeVar<MapCoordinates | null>(null);
export const metricsLoadingVar = makeVar<boolean>(false);
export const isPropertyModalOpenVar = makeVar<boolean>(false);
export const propertyInfoVar = makeVar<PropertyInfo>({});
export const downloadCsvVar = makeVar<boolean>(false);
export const guidedTourVar = makeVar<GuidedTour | undefined>(undefined);
export const toursModalOpenVar = makeVar<boolean>(false);
export const openCollapsibleContentVar = makeVar<boolean>(false);
export const filterProviderTypeVar = makeVar<string[]>(['airbnb']);
export const filterInstantBookTypeVar = makeVar<string[]>(['all']);
export const filterHostTypeVar = makeVar<string[]>(['all']);
export const filterArrangementTypeVar = makeVar<string[]>(['all']);
export const filterBathroomTypeVar = makeVar<string[]>(['all']);
export const filterGuestsTypeVar = makeVar<string[]>(['all']);
export const filterRoomsTypeVar = makeVar<string[]>(['all']);
export const filterPropertyTypeVar = makeVar<string[]>(['all']);
export const filterAmenitiesTypeVar = makeVar<string[]>(['all']);
export const filterHouseRulesTypeVar = makeVar<string[]>(['all']);
export const lastSubAreaVar = makeVar<number | undefined>(undefined);
export const statsMatchSubAreaVar = makeVar<boolean>(true);
export const centerMapVar = makeVar<boolean>(false);
export const isRefetchVar = makeVar<boolean>(false);
export const isNewFollowAreaVar = makeVar<boolean>(false);
export const isPropertyTrackedVar = makeVar<boolean>(false);
export const countryNumberVar = makeVar<number>(0);
export const countryShortCodeVar = makeVar<string>('');
export const stateShortCodeVar = makeVar<string>('');
export const defaultCountryVar = makeVar<string>('Country');
export const defaultStateVar = makeVar<string>('State');
export const isCompSetModalOpenVar = makeVar<boolean>(false);
export const applyFiltersActionVar = makeVar<boolean>(false);
export const listingIdVar = makeVar<string>('');
export const hasDeletedCustomMarketVar = makeVar<boolean>(false);
export const hasDeletedCustomCompSetVar = makeVar<boolean>(false);
export const hasDeletedListingVar = makeVar<boolean>(false);
export const overlayCompPageVar = makeVar<boolean>(false);
export const newCompSetVar = makeVar<number | null>(null);
export const newMarketVar = makeVar<number | null>(null);
export const yearsValueVar = makeVar<number>(3);
export const resetOptionsExploreVar = makeVar<boolean>(false);
export const avgRangeVar = makeVar<number[]>([0, 0]);
export const compareOverlayVar = makeVar<boolean>(false);
export const exploreTableAreaIdVar = makeVar<number | undefined>(undefined);
export const purchasedAreaVar = makeVar<boolean>(false);
export const setOverlay = makeVar<boolean>(false);

export enum ProviderEnum {
  AIRBNB = 'airbnb',
  HAVRBO = 'havrbo',
}

export const dataAnalyzeSectionVar = makeVar<DataAnalyzeSection['dataAnalyzeSection']>({
  since: '2018-01-20',
  until: '2020-10-31',
  granularity: 'monthly',
});

export const dataAnalyzeSectionModalVar = makeVar<DataAnalyzeSection['dataAnalyzeSection']>({
  since: '2018-01-20',
  until: '2020-10-31',
  granularity: 'monthly',
});

export const defaulDateRangeVar = makeVar({
  since: '2018-01-20',
  until: '2020-10-31',
});

export const DEFAULT_GRANULARITY = 'monthly';
export const DEFAULT_GRANULARITY_WEEKLY = 'weekly';

// historical performance
export const historicalDateRangeTypeVar = makeVar<string>('custom');
export const futureSelectedMonthTypeVar = makeVar<string>('');

export const resetStore = () => {
  isLoginModalOpenVar(false);
  isSideMenuOpenVar(false);
  isCurrencyModalOpenVar(false);
  isMarketMenuOpenVar(false);
  isSubscribeModalOpenVar(false);
  isSideFiltersOpenVar(false);
  isPropertyModalOpenVar(false);
  globalNotificationVar(null);

  metricsVar(['adr_booked']);
  FBImetricsVar(['q_adr_booked']);
  notificationsVar([]);

  filterProviderTypeVar(['airbnb']);
  filterInstantBookTypeVar(['all']);
  filterHostTypeVar(['all']);
  filterArrangementTypeVar(['all']);
  filterBathroomTypeVar(['all']);
  filterGuestsTypeVar(['all']);
  currentMapCoordinates(null);
  statsMatchSubAreaVar(true);

  dataAnalyzeSectionVar({
    ...defaulDateRangeVar(),
    granularity: DEFAULT_GRANULARITY,
  });

  const client = getApolloClient();
  client.resetStore();
};

export const percentilesVar = makeVar<PercentileId[]>([]);
export const FBIpercentilesVar = makeVar<FBIPercentileId>('entire_market');

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      isLoginModal: {
        read() {
          return isLoginModalOpenVar();
        },
      },
      isSideMenu: {
        read() {
          return isSideMenuOpenVar();
        },
      },
      isCurrencyModal: {
        read() {
          return isCurrencyModalOpenVar();
        },
      },
      isMarketMenu: {
        read() {
          return isMarketMenuOpenVar();
        },
      },
      isSubscribeModal: {
        read() {
          return isSubscribeModalOpenVar();
        },
      },
      globalNotification: {
        read() {
          return globalNotificationVar();
        },
      },
      currencyCode: {
        read() {
          return currencyCodeVar();
        },
      },
      metrics: {
        read() {
          return metricsVar();
        },
      },
      FBImetrics: {
        read() {
          return FBImetricsVar();
        },
      },
      notifications: {
        read() {
          return notificationsVar();
        },
      },
      dataAnalyzeSection: {
        read() {
          return dataAnalyzeSectionVar();
        },
      },
      percentiles: {
        read() {
          return percentilesVar();
        },
      },
      isSideFiltersOpen: {
        read() {
          return isSideFiltersOpenVar();
        },
      },
    },
  },
};
