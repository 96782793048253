import * as React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import styles from './SandboxHeader.module.scss';

type Props = {};

const SandboxHeader: React.FunctionComponent<Props> = () => {
  const { pathname } = useRouter();
  const isEnterprise = pathname.includes('/enterpriseV2');
  const isStaging = process.env.IS_STAGING;

  return isStaging ? (
    <div className={clsx(styles.SandboxHeader, isEnterprise && styles.SandboxHeaderEnterprise)}>
      <span>This is a sandbox environment</span>
    </div>
  ) : null;
};

export default SandboxHeader;
