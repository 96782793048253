import { useClerk, useAuth } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';

export const useClerkToken = () => {
  const [token, setToken] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  const { getToken, isSignedIn } = useAuth();
  const { signOut } = useClerk();

  useEffect(() => {
    const fetchClerkToken = async () => {
      try {
        const clerkToken = await getToken();
        setToken(clerkToken);
      } catch (err) {
        signOut();
        if (err instanceof Error && isSignedIn) {
          setError(err.message);
        }
      }
    };

    fetchClerkToken();
  }, [isSignedIn, getToken, signOut]);

  return { token, error, getToken };
};
