import * as React from 'react';
import Link from 'next/link';
import clsx, { ClassValue } from 'clsx';
import { LogoDoors } from '@components/iconography/LogoDoors/LogoDoors';

import styles from './Brand.module.scss';

type Props = {
  className?: ClassValue;
  size?: 'big';
  hideName?: boolean;
  beta?: boolean;
  href?: string;
};

export const Brand: React.FunctionComponent<Props> = ({ className, size, hideName, href }) => (
  <div className={styles.Container}>
    {href && (
      <Link className={clsx(styles.Brand, className)} href={href} data-test="Brand">
        <LogoDoors />
        <div className={clsx(styles.BrandNameContainer, hideName && styles.hidden, size === 'big' && styles.big)}>
          <div className={styles.BrandName}>AllTheRooms.</div>
        </div>
      </Link>
    )}
    {!href && (
      <a className={clsx(styles.Brand, styles.NoHover, className)} data-test="Brand">
        <LogoDoors />
        <div className={clsx(styles.BrandNameContainer, hideName && styles.hidden, size === 'big' && styles.big)}>
          <div className={styles.BrandName}>AllTheRooms.</div>
        </div>
      </a>
    )}
  </div>
);
