/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { useRouter } from 'next/router';
import useCookie from 'react-use-cookie';
import { AppProps } from 'next/app';
import uuid from 'uuid-random';

const getDisplayName = (Component: React.FC<AppProps>) => Component.name || 'Unknown';

const setConfig = (key: string) => {
  const config: SplitIO.IBrowserSettings = {
    core: {
      // Use 'localhost' to enable features in dev/test mode
      // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#localhost-mode
      authorizationKey: process.env.ANALYTICSDASH_SPLITIO_AUTH_KEY || 'localhost',
      key,
    },
  };

  // localhost-mode toggles state
  // NOTE: `authorizationKey` needs to be 'localhost' to use this object
  config.features = {
    sign_in_popup_experiment: 'on',
    maintenance_app: 'off',
  };

  return config;
};

const TOGGLE_OVERRIDE_KEY = process.env.ANALYTICSDASH_TOGGLE_OVERRIDE_COOKIE_NAME || 'splitiokey';
const TO_NO_AVAIL_KEY = uuid();

export const withSplit = (WrappedComponent: React.FC<any>) => {
  const WithSplit = (props: AppProps) => {
    if (!process.browser) {
      return <WrappedComponent {...props} />;
    }

    const {
      query: { [TOGGLE_OVERRIDE_KEY]: queryKey },
    } = useRouter();
    const [cookieKey, setCookieKey] = useCookie(TOGGLE_OVERRIDE_KEY, '');
    const key = (queryKey as string) || cookieKey || TO_NO_AVAIL_KEY;

    // update cache asap to spread the
    // override on-time to other services
    if (key !== cookieKey) {
      setCookieKey(key);
    }

    return (
      <SplitFactory config={setConfig(key)}>
        <WrappedComponent {...props} />
      </SplitFactory>
    );
  };

  WithSplit.displayName = `WithSplit(${getDisplayName(WrappedComponent)})`;

  return WithSplit;
};
