export const EMAILS = [
  'felipe@vestrepartners.com',
  'tim@mjhomesandrealty.com',
  'danny@optimizemyairbnb.com',
  'ccm@luckyshepherd.io',
  'chris_sang@yahoo.com',
  'pbreslin@horwathhtl.com',
  'ssun@horwathhtl.com',
  'bgnecchiruscone@horwathhtl.com',
  'buck@align.vc',
  'adam.vojdany@gmail.com',
  'tmchugh@hodgeswardelliott.com',
  'jeffrey.levy@hhmlp.com',
  'sarah@rihospitality.org',
  'ncjlai@yahoo.com',
  'jay@merrittadvisory.com',
  'andreasrothe@gmail.com',
  'phs_necc@hotmail.com',
  'nv_628@yahoo.com',
  'bboufarah@deloitte.com',
  'chris@cp.ventures',
  'hkaufman@loeb.nyc',
  'dylan@simondata.com',
  'dougstcyr@gmail.com',
  'phs_necc@hotmail.com',
  'ben@springboardhospitality.com',
  'pskae@skaepower.com',
  'abankier@gmail.com',
  'abankier@gmail.com',
  'jasklein@gmail.com',
  'vpsalesnyc@gmail.com',
  'fgrapstein@yahoo.com',
  'jj.feifer@parkstonemanagement.com',
  'alutzke@yahoo.com',
  'jgaronce@drakerep.com',
  'msearles@merrittadvisory.com',
  'tflanagan@ahla.com',
  'tblum@newyorkangels.com',
  'johnseitz35@gmail.com',
  'jlenahan@alltherooms.com',
  'hkaufman@loeb.nyc',
  'adam@id8investments.com',
  'dcotterman@drakerep.com',
  'richardselsky@gmail.com',
  'glallier@uks.com',
  'gpjudge@gmail.com',
  'livanos@gmail.com',
  'andreasrothe@gmail.com',
  'schles@gmail.com',
  'jpotashnik@ahla.com',
  'sarah@rihospitality.org',
  'lserrano@prhta.net',
  'seonju.lawrence@hhmlp.com',
  'jason.altberger@sagehospitalitygroup.com',
  'matt@hospitalitymaine.com',
  'montinemcnulty@gmail.com',
  'seonju.lawrence@hhmlp.com',
  'greg@hospitalitymaine.com',
  'seonju.lawrence@hhmlp.com',
  'gary.isenberg@lwhadvisors.com',
  'bill.walsh@hersha.com',
  'jay.shah@hersha.com',
  'jriederer@ahla.com',
  'greg@hospitalitymaine.com',
  'mbreazeale@ashfordinc.com',
  'lbennett@str.com',
  'jeplunket@gmail.com',
  'bruce@lodgingeconometrics.com',
  'pam.zavatto@marriott.com',
  'zachariah.demuth@am.jll.com',
  'cleffet@hvs.com',
  'victor.bosselaar@gmail.com',
  'roberto@alltherooms.com',
  'rhill@gettys.com',
  'ssykes@ahla.com',
  'kwright@ahla.com',
  'adam.schlesinger@mail.mcgill.ca',
  'sean.rakidzich@gmail.com',
  'zachariah.demuth@jll.com',
  'jason.pitard@bestwestern.com',
  'gary.romano@bestwestern.com',
  'monte.gardiner@bestwestern.com',
  'robuiltchannel@gmail.com',
  'hello@pointanalytics.co',
  'michelle.ugaste@bestwestern.com',
  'desiree.mcdonald@bestwestern.com',
  'jeffrey.heath@bestwestern.com',
  'shanon.jones@bestwestern.com',
  'jennifer.giller@bestwestern.com',
  'david.slotsve@bestwestern.com',
  'thomas.wilson@bestwestern.com',
  'dehn.blunt@bestwestern.com',
  'mia.king@bestwestern.com',
  'amit.somani@bestwestern.com',
  'susan.stankus@bestwestern.com',
  'christopher.heinsohn@bestwestern.com',
  'kara.cox@bestwestern.com',
  'olivier.cusson@bestwestern.com',
  'jillian.gartenbaum@bestwestern.com',
  'jacob.flores@bestwestern.com',
  'meesha.anderson@bestwestern.com',
  'shelby.thomas@bestwestern.com',
  'karen.berthelson@bestwestern.com',
  'tonya.taylor@bestwestern.com',
  'natasha.kanji@bestwestern.com',
  'cara.beasley@bestwestern.com',
  'tammy.michelbrink@bestwestern.com',
  'carl.fletcher@bestwestern.com',
  'elizabeth.nickoloff@bestwestern.com',
  'anthony.pircher@bestwestern.com',
  'sonhui.mason@bestwestern.com',
  'paula.drown@bestwestern.com',
  'michael.roberts@bestwestern.com',
  'lynn.gailey@bestwestern.com',
  'mark.gosiewski@bestwestern.com',
  'richard.mcpherson@bestwestern.com',
  'logan.wojtaleqwicz@bestwestern.com',
  'shamik.bhardwaj@bestwestern.com',
  'chris.flear@bestwestern.com',
  'jacquelyn.thorpe@bestwestern.com',
  'paul.trinh@bestwestern.com',
  'susan.marcum@bestwestern.com',
  'jennifer.holmes@bestwestern.com',
  'christine.kramer@bestwestern.com',
  'crogers@ahla.com',
  'kcarey@ahla.com',
  'jed.kelly@opco.com',
  'joshua.marin@opco.com',
  'ks@ksingresos.com',
  'christine@menedis.com',
  'jriederer@ahla.com',
  'mcarrier@ahla.com',
  'kfiler@ahla.com',
  'tflanagan@ahla.com',
  'jriederer@ahla.com',
  'mhalvorsen@ahla.com',
  'kcarey@ahla.com',
];
