import { Auth0Provider } from '@auth0/auth0-react';
import React, { FC } from 'react';

type Auth0ProviderProps = {
  children?: React.ReactNode;
};

const Auth0: FC<Auth0ProviderProps> = ({ children }) => {
  const redirectUri = process.env.IS_STAGING
  ? process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI_STAGING
  : process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI;

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
      useRefreshTokens
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!,
        max_age: 60 * 60 * 24 * 7,
        scope: 'offline_access email',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0;
