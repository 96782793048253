import LogRocket from 'logrocket';
import cookie from 'js-cookie';

const LOGROCKET_COOKIE_NAME = 'disable-logrocket';

export const isLogRocket = (isLogin?: boolean) => !cookie.get(LOGROCKET_COOKIE_NAME) && isLogin;

export const loadLogRocket = (isLogin?: boolean) => {
  if (!process.browser || process.env.MONITORING) return;

  const query = window.location.search;
  if (query === '?disable-logrocket') cookie.set(LOGROCKET_COOKIE_NAME, 'true', { expires: 365 });
  if (query === '?disable-logrocket=off') cookie.remove(LOGROCKET_COOKIE_NAME);

  const isLogRocketActive = isLogRocket(isLogin);
  if (isLogRocketActive) {
    LogRocket.init('k14xni/atra');
  }
};

export const isLogRocketSession = () => {
  let isSession = false;

  if (LogRocket) {
    LogRocket.getSessionURL((sessionURL) => {
      isSession = sessionURL.includes('http');
    });
  }

  return isSession;
};
