import { NotificationItem } from '@interfaces/UI';
import { InMemoryCache, Reference } from '@apollo/client';

type Props = Pick<NotificationItem, 'id' | 'title' | 'description' | 'type'>;
type Context = {
  cache: InMemoryCache;
};

export default {
  Mutation: {
    createNotification: (_: any, { id, title, description, type }: Props, { cache }: Context) => {
      const newNotification: NotificationItem = {
        id,
        title,
        description,
        type,
      };

      cache.modify({
        fields: {
          notifications(existingNotificationRefs) {
            return [...existingNotificationRefs, newNotification];
          },
        },
      });

      return newNotification.id;
    },
    deleteNotification: (_: any, { id }: Pick<Props, 'id'>, { cache }: Context) => {
      cache.modify({
        fields: {
          notifications(existingNotificationRefs, { readField }) {
            return existingNotificationRefs.filter(
              (notificationRef: Reference) => id !== readField('id', notificationRef),
            );
          },
        },
      });
    },
  },
};
