export enum marketingURLS {
  HOME = 'https://www.alltherooms.com',
  ABOUT = 'https://www.alltherooms.com/about-us',
  CONTACT_US = 'https://www.alltherooms.com/contact-us',
  FAQ = 'https://www.alltherooms.com/resources/faqs',
  ENTERPRISE = 'https://www.alltherooms.com/enterprise',
  METRICS_GLOSSARY = 'https://alltherooms.com/resources/metrics-glossary/',
  PRICING = 'https://www.alltherooms.com/pricing',
  INSIGHTS = 'https://alltherooms.com/resources/?tab=insights',
  TERMS_AND_CONDITIONS = 'https://alltherooms.com/terms-and-conditions/',
  KNOWLEDGE_BASE = 'https://alltherooms.com/resources/?tab=knowledge-base',
}

export const CONTACT_US_WEBAPP = '/contact-us';
