// https://segment.com/docs/connections/spec/track/
/* eslint-disable max-len */

import { EventId, EventOptions } from '@interfaces/Analitycs';

import { PlanTypeEnum } from './enums';
import { sessionId } from './sessionId';

type PageCallsArgs = {
  url: string;
  title: string;
  path: string;
  referrer?: string;
  search?: string;
};

export type UserAreaTrait = {
  areaId: number;
  planType: PlanTypeEnum;
  period: string | null;
  // add period
};

export type UserTraits = {
  name: string;
  email: string;
  createdAt: string;
  userProfile: string;
  userType: 'active' | 'inactive';
  userAccess: 'premium' | 'free';
  userPlans: UserAreaTrait[];
};

// Segment options => Amplitude
// https://segment.com/docs/connections/destinations/catalog/amplitude/#advanced-amplitude-features
const options = {
  integrations: {
    Amplitude: {
      session_id: 0,
      includeUtm: true,
      includeReferrer: true,
    },
  },
};

const isBlocked = () => {
  if (process.env.TRACK || process.env.MONITORING) {
    return false;
  }

  return typeof window === 'undefined' || !(window as any).analytics;
};

const consoleFakeMethod = (methodName: string, obj: any = {}) => {
  // eslint-disable-next-line no-console
  console.info(methodName, obj);
};

const analyticsTrack = async (event: string, payload: any = {}) => {
  if (process.env.TRACK) {
    consoleFakeMethod('track', { event, payload, options });
  } else {
    options.integrations.Amplitude.session_id = await sessionId(event);
    (window as any).analytics.track(event, payload, options);
  }
};

const analyticsIdentify = async (id: string, traits: UserTraits): Promise<void> => {
  if (process.env.TRACK) {
    consoleFakeMethod('identify', { id, traits, options });
  } else {
    options.integrations.Amplitude.session_id = await sessionId('identify');
    (window as any).analytics.identify(id, traits, options);
  }
};

const analyticsPage = async (payload: PageCallsArgs) => {
  if (process.env.TRACK) {
    consoleFakeMethod('page', payload);
  } else {
    options.integrations.Amplitude.session_id = await sessionId('page');
    (window as any).analytics.page(payload, options);
  }
};

export const appEvent = (event: EventId, payload: EventOptions) => {
  if (isBlocked()) return;
  analyticsTrack(event, payload);
};

export const identify = (id: string, traits: UserTraits) => {
  if (isBlocked()) return;
  analyticsIdentify(id, traits);
};

export const appEventWrap =
  (fn: Function, event: EventId, payload: EventOptions) =>
  (...args: any[]) => {
    fn(...args);
    appEvent(event, payload);
  };

export const appPageView = (payload: PageCallsArgs) => {
  if (isBlocked()) return;
  analyticsPage(payload);
};

export const SEGMENT_TRACKING_ID = process.env.IS_STAGING
  ? process.env.STAGING_ANALYTICSDASH_SEGMENT_KEY
  : 'aSNy9QQNgTiuZhOVrQ7GegetsBiqdjm7';
