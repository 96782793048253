/* eslint-disable no-console */
import { axios } from './axios';
import { urlBase64ToUint8Array } from './urlBase64ToUint8Array';

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () => {
  let isPWAinBrowser = false;
  if (typeof window !== 'undefined') {
    // replace standalone with fullscreen or minimal-ui according to your manifest
    if (matchMedia('(display-mode: standalone)').matches) {
      // Android and iOS 11.3+
      isPWAinBrowser = true;
    } else if ('standalone' in window.navigator) {
      // useful for iOS < 11.3
      // eslint-disable-next-line dot-notation
      isPWAinBrowser = window.navigator['standalone'] as boolean;
    }
  }
  return isPWAinBrowser;
};

let appInstalled = false;

const onSubscribe = (subscription: PushSubscription) => {
  // Sending push
  axios({
    url: '/subscribe',
    method: 'POST',
    data: subscription,
    headers: { 'Content-Type': 'application/json' },
  }).catch((err) => {
    console.log('Subscription failed: ', err);
  });
};

export const PNRegister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      // Registering push
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          // The `urlBase64ToUint8Array()` function is the same as in
          // https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
          applicationServerKey: urlBase64ToUint8Array(process.env.PUBLIC_VAPID_KEY || ''),
        })
        .then(onSubscribe)
        .catch((err) => {
          console.log('Registration failed: ', err);
        });
    });
  }
};

// this will update always because the browser force that
// https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#updates
// https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
export const SWRegister = (cb?: () => void) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`/service-worker.js`, { scope: '/' })
      .then((registration) => {
        if (!registration) return;

        if (cb) cb();
      })
      .catch((err) => {
        console.log('ServiceWorker registration failed: ', err);
      });
  } else {
    console.log('Service worker not supported');
  }
};

export const SWUnRegister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((regs = []) => {
      regs.forEach((registration) => registration.unregister());
    });

    // remove Caches
    caches.keys().then((names = []) => {
      names.forEach((name) => caches.delete(name));
    });
  }
};

// work only in chrome
export const AppInstalled = (cb: (evt?: Event) => void) => {
  if (typeof window === 'undefined' && 'onappinstalled' in window) return;
  window.addEventListener('appinstalled', (evt) => {
    appInstalled = true;
    cb(evt);
  });
};

export const isAppInstalled = () => appInstalled;
