import '../src/style/_App.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { appEvent, appPageView } from '@utils/analytics';
import { AppInstalled, SWRegister } from '@utils/SWRegister';
import { withSplit } from '@utils/withSplit';
import { AppProps } from 'next/app';
import { LoadingLayer } from '@components/feedback/LoadingLayer/LoadingLayer';
import { isLogRocketSession, loadLogRocket } from '@utils/logrocket';
import Head from 'next/head';
// import { sentryInit } from '@utils/sentry';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@apolloCli/apolloClient';
import { AppMaintenance } from '@components/other/ShutDownApp/AppMaintenance';
import { useSplitio } from '@hooks/useSplitioSwitch';
import { APP_IN_MAINTENANCE } from '@const/features';
import SandboxHeader from '@components/layout/SandboxHeader/SandboxHeader';
import { rehydrateStore } from '@store/store';
import Auth0 from '@components/Auth0/Auth0Provider';
import * as gtag from '@utils/gtag';
import { ThemeProvider } from '@mui/material';
import { theme } from '@style/theme';
import { ClerkProvider } from '@clerk/nextjs';
import { useClerkToken } from '@hooks/useClerkToken';
import { useUser } from '@hooks/useUser';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    const stripePublicKey =
      process.env.NODE_ENV === ('staging' as string) || process.env.IS_STAGING
        ? process.env.STAGING_ANALYTICSDASH_STRIPE_PUBLIC_KEY
        : process.env.ANALYTICSDASH_STRIPE_PUBLIC_KEY;

    stripePromise = loadStripe(stripePublicKey as string);
  }
  return stripePromise;
};

// sentryInit();

type ApolloProviderWrapperProps = {
  children?: React.ReactNode;
  pageProps: any;
};

const ApolloProviderWrapper: React.FC<ApolloProviderWrapperProps> = ({ children, pageProps }) => {
  const { getToken } = useClerkToken();

  const apolloClient = useApollo(pageProps, getToken);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const LogRocketWrapper = () => {
  const [startLogRocket, setStartLogRocket] = useState(false);
  const { isLogin } = useUser();

  useEffect(() => {
    setStartLogRocket(!!isLogin);
  }, [isLogin]);

  useEffect(() => {
    const isLogRocketRunning = isLogRocketSession();
    if (!isLogRocketRunning && startLogRocket) {
      loadLogRocket(isLogin);
    }
  }, [isLogin, startLogRocket]);

  useEffect(() => {
    loadLogRocket(isLogin);
  }, [isLogin]);

  return null;
};

const App: React.FC<AppProps & { err: unknown }> = ({ Component, pageProps, err }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAppMounted, setIsAppMounted] = useState(false);
  const { events, pathname } = useRouter();
  const { isActive, isReady } = useSplitio({ featureId: APP_IN_MAINTENANCE });
  const isMaintenance = isActive && isReady;

  useEffect(() => {
    const { search, pathname: path } = window.location;
    const { title, referrer } = window.document;

    const routeChangeStart = () => {
      setIsLoading(true);
    };

    const routeChangeComplete = (url: string) => {
      setIsLoading(false);
      appPageView({ url, search, path, title, referrer });
      gtag.pageView(url);
    };

    const routeChangeError = () => {
      setIsLoading(false);
    };

    events.on('routeChangeStart', routeChangeStart);
    events.on('routeChangeComplete', routeChangeComplete);
    events.on('routeChangeError', routeChangeError);

    return () => {
      events.off('routeChangeStart', routeChangeStart);
      events.off('routeChangeComplete', routeChangeComplete);
      events.off('routeChangeError', routeChangeError);
    };
  }, [events]);

  useEffect(() => {
    SWRegister();
    AppInstalled(() => {
      // PNRegister()
    });
  }, []);

  // Rehydrate the store with the initial state
  useEffect(() => {
    rehydrateStore();
    setIsAppMounted(true);
  }, []);

  useEffect(() => {
    if (isAppMounted) {
      appEvent('App Opened', {
        onPage: pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppMounted]);

  return (
    <ClerkProvider
      appearance={{
        signIn: {
          variables: { colorPrimary: '#0077ff' },
          elements: {
            otpCodeFieldInput: {
              padding: '7px !important',
              height: '21px !important',
            },
          },
        },
      }}
    >
      <Head>
        <meta name="viewport" content="viewport-fit=cover" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {isMaintenance ? (
        <AppMaintenance />
      ) : (
        <ThemeProvider theme={theme}>
          <Elements stripe={getStripe()}>
            <Auth0>
              <ApolloProviderWrapper pageProps={pageProps}>
                <SandboxHeader />
                <Component {...pageProps} err={err} />
                <LogRocketWrapper />
              </ApolloProviderWrapper>
            </Auth0>
            {isLoading && <LoadingLayer fixed />}
          </Elements>
        </ThemeProvider>
      )}
    </ClerkProvider>
  );
};

export default withSplit(App);
