import React from 'react';
import { Brand } from '@components/iconography/Brand/Brand';
import { CONTACT_US_WEBAPP } from '@utils/links';

import styles from './AppMaintenance.module.scss';

export const AppMaintenance = () => (
  <div className={styles.Container}>
    <div className={styles.Logo}>
      <Brand href="/" />
    </div>
    <div className={styles.LogoDesktop}>
      <Brand href="/" size="big" />
    </div>

    <h5 className={styles.Title}>
      We are in <span className={styles.Maintenance}>maintenance</span>
    </h5>
    <img src="/images/maintenance.svg" className={styles.Image} />

    <span className={styles.MainText}>
      Oops, <br />
      AllTheRooms is currently undergoing some maintenance. We are busy making improvements! Don't worry, we will be
      live again soon.
    </span>

    <a href={CONTACT_US_WEBAPP} className={styles.Contact}>
      Contact us
    </a>
  </div>
);
