import {
  Resolvers,
} from '@apollo/client';
import { getAxios } from '@utils/axios';

import accountResolvers from './resolvers/account';
import notificationResolvers from './resolvers/notifications';

export const resolvers: Resolvers = {
  Query: {
    ...accountResolvers.Query,
  },

  Mutation: {
    ...accountResolvers.Mutation,
    ...notificationResolvers.Mutation,

    unsubscribe: async (_, { token }) => {
      let statusMessage = '';
      if (!token) throw new Error('Token not supplied');

      try {
        const decodedToken = decodeURIComponent(token as string);
        const axios = getAxios();
        const response = await axios({
          url: '/api/subscription',
          method: 'PUT',
          data: { token: decodedToken },
        });

        const { status } = response;

        if (status >= 200 && status < 300) {
          // do not send ga events if already confirmed
          if (status === 201) return true;
          return null;
        }

        statusMessage = 'Email Unsubscription Failed';
      } catch (error: any) {
        const { message } = error;
        throw new Error(`Email Unsubscription Unkown Error: ${message}`);
      }
      throw new Error(statusMessage);
    },
  },
};
