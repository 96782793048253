export const queryFactory = (rootUrl: string, baseOpts?: any) => async (
  typeName: string,
  urlStub: string,
  opts?: any,
) => {
  const response = await fetch(`${rootUrl}/${urlStub}`, { ...opts, ...baseOpts });
  const ok = response.ok || response.redirected;
  const data = await (ok ? response.json() : response.text());

  if (!ok) throw new Error(data);

  return Array.isArray(data)
    ? data.map((d) => ({ ...d, __typename: typeName }))
    : {
      __typename: typeName,
      ...data,
    };
};
