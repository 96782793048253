export const GA_TRACKING_ID = 'AW-734822278';

export const pageView = (url: string) => {
  if (process.env.NODE_ENV !== 'production') return;
  gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

export const event = ({ category, sendTo }: { category: string; sendTo: string }) => {
  if (process.env.NODE_ENV !== 'production') return;
  gtag('event', category, {
    send_to: sendTo,
  });
};
