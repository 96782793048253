import * as React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './LogoDoors.module.scss';

type Props = {
  loop?: boolean;
  breakLine?: boolean;
  size?: 'xs' | 's' | 'm' | 'l';
  className?: ClassValue;
  position?: 'center'
}

export const LogoDoors = ({
  loop = false, breakLine, size = 's', className, position = 'center',
}: Props) => (
  <div className={clsx(
    styles.LoadingDoors,
    styles.animated,
    loop && styles.loop,
    breakLine && styles.breakLine,
  )}
  >
    <div className={clsx(styles.doors,
      className,
      position === 'center' && styles.center)}
    >
      <div className={clsx(styles.door, styles.s_red, styles[size])} />
      <div className={clsx(styles.door, styles.s_yellow, styles[size])} />
      <div className={clsx(styles.door, styles.s_blue, styles[size])} />
    </div>
  </div>
);
