import { ADD_CLIENT_SESSION } from '@apolloCli/mutations/client';
import cookie from 'js-cookie';

type Props = {
  sessionKey: string;
  event: string;
};

const addClientSession = async ({ sessionKey, event }: Props): Promise<{ added: boolean }> => {
  try {
    const domain = process.env.IS_STAGING
      ? 'https://analytics-staging.alltherooms.com'
      : process.env.GRAPH_ORIGIN || process.env.DOMAIN || '';
    const response = await fetch(`${domain}/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        variables: { sessionKey, event },
        query: ADD_CLIENT_SESSION,
      }),
    });
    const result = await response.json();
    return result.data?.addClientSession;
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 *it will be created a new session id in two uses cases.
 * 1. When a user starts the session.
 * 2. When a user comes back after 30 minutes of inactivity.
 * - Amplitude standard time https://help.amplitude.com/hc/en-us/articles/115002323627
 */
export async function sessionId(event: string): Promise<number> {
  const maximumInactiveMinutes = 30;
  const maximumRefreshMinutes = 1;
  const currentSession = cookie.get('sessionId');
  const lastEventId = Number(cookie.get('lastSentEventId'));

  const newSessionId = Date.now();
  const newEventId = Date.now();

  if (currentSession && lastEventId) {
    const minutesBetweenLastEventToNewEventId =
      Math.abs((new Date(newEventId).getTime() - new Date(lastEventId).getTime()) / 1000) / 60;
    const removeCookie = minutesBetweenLastEventToNewEventId >= maximumInactiveMinutes;
    const refreshSession = minutesBetweenLastEventToNewEventId >= maximumRefreshMinutes;

    if (refreshSession) {
      cookie.set('lastSentEventId', String(newEventId));
      await addClientSession({ sessionKey: String(newSessionId), event });
    }

    if (removeCookie) {
      cookie.set('sessionId', String(newSessionId));
      return +newSessionId;
    }

    return +currentSession;
  }

  cookie.set('lastSentEventId', String(newEventId));
  cookie.set('sessionId', String(newSessionId));
  await addClientSession({ sessionKey: String(newSessionId), event });

  return +newSessionId;
}
