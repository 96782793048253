import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const themePalette = {
  primary: {
    main: '#3593fc',
    dark: '#0077ff',
    light: '#DEECFF',
    contrastText: '#F9FCFF',
  },
  secondary: {
    main: '#ffc753',
    dark: '#ffb400',
    light: '#fff5e1',
  },
  gray: {
    main: '#a8aaae',
    dark: '#777b81',
    light: '#edeff3',
  },
  black: {
    main: '#1d2129',
    dark: '#000000',
    light: '#454950',
  },
  white: {
    main: '#ffffff',
  },
  red: {
    main: '#ff0000',
    dark: '#d32f2f',
    light: '#fedadc',
  },
};

export const theme = createTheme({
  palette: themePalette,
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'green',
          color: 'white',
        },
        standardError: {
          backgroundColor: 'red',
          color: 'white',
        },
        standardWarning: {
          backgroundColor: '#FFB933',
          color: 'black',
        },
        standardInfo: {
          backgroundColor: 'grey',
          color: 'black',
        },
      },
    },
  },
});
