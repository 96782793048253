import gql from 'graphql-tag';

export const GET_CLIENT = gql`
  query getUser {
    client {
      id
      uuid
      name
      email
      urls
      confirmed
      subscribedVrps
      subscribedMarketing
      betaAccess
      dashboardVersion
      userDescriptionType
      currency
      createdAt
      isPremiumUser
    }
  }
`;
