import { Resolvers } from '@apollo/client';
import { SubscriptionApiInput } from '@hooks/useCreateApiSubscription';

import { CancelSubscriptionInput } from '../../hooks/useCancelSubscription';
import { SubscriptionInput, SubscriptionInputV2 } from '../../hooks/useCreateSubscription';

import { queryFactory } from './utils';

const domain = process.env.IS_STAGING
  ? 'https://analytics-staging.alltherooms.com'
  : process.env.ACCOUNT_ORIGIN || process.env.DOMAIN || '';

const query = queryFactory(`${domain}/account`, {
  credentials: process.env.ACCOUNT_ORIGIN ? 'include' : 'same-origin',
});

const queries = {
  availablePlansV2: async (_: any, args: { areaId: number; getToken: () => any }) => {
    const { areaId, getToken } = args;
    const token = await getToken();

    return query('AvailablePlansV2', `available-plans-v2/${areaId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  },

  coupon: async (_: any, args: { code: string; getToken: () => any }) => {
    const { code, getToken } = args;
    const token = await getToken();
    return query('Coupon', `coupons/${code}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  },

  // not being used
  subscriptions: async ({ getToken }: { getToken: () => any }) => {
    const token = await getToken();
    return query('Subscriptions', `subscriptions`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  },

  // not being used
  subscriptionsV2: async (_: any, { token }: { token: string | undefined }) =>
    query('SubscriptionsV2', `subscriptionsV2`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    }),

  customer: async (_: any, { getToken }: { getToken: () => any }) => {
    const token = await getToken();
    return query('Customer', `customer`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  },

  getApiPlans: async (_: any, { getToken }: { getToken: () => any }) => {
    const token = await getToken();
    return query('ApiPlans', `subscriptions/api/plans`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  },

  listEnterpriseApiSubscriptions: async (_: any, { getToken }: { getToken: () => any }) => {
    const token = await getToken();
    return query('EnterpriseApiSubscriptions', `subscriptions/api`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
};

const mutation = {
  subscriptions: async (_: any, { areaId, coupon, papCode, promoCode, getToken }: SubscriptionInput) => {
    let token;
    if(getToken){
      token = await getToken();
    }
    const body = {
      orderData: {
        area_id: areaId,
      },
      coupon,
      papCode,
      promoCode,
    };

    const options = {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('Subscriptions', `subscriptions`, options);
  },

  subscriptionsV2: async (_: any, { areaId, coupon, papCode, planId, promoCode, getToken }: SubscriptionInputV2) => {
    let token;
    if(getToken){
      token = await getToken();
    }
    const body = {
      planId,
      orderData: {
        area_id: areaId,
      },
      coupon,
      papCode,
      promoCode,
    };

    const options = {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('SubscriptionsV2', `subscriptionsV2`, options);
  },

  addOrderApiEnterprise: async (_: any, { productId, orderData, getToken }: SubscriptionApiInput) => {
    let token;
    if(getToken){
      token = await getToken();
    }
    const body = {
      productId,
      orderData,
    };

    const options = {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('SubscriptionsApi', `subscriptions/api`, options);
  },

  // not being used
  restartedSubscription: async (_: any, { id, getToken }: CancelSubscriptionInput) => {

    let token;
    if(getToken){
      token = await getToken();
    }
    const body = {
      action: 'restart',
    };

    const options = {
      method: 'put',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('RestartedSubscription', `subscriptions/${id}`, options);
  },

  restartedSubscriptionV2: async (_: any, { areaId, getToken }: { areaId: string; getToken: () => any }) => {
    const token = await getToken();
    const body = {
      action: 'restart',
    };

    const options = {
      method: 'put',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('RestartedSubscription', `subscriptionsV2/${areaId}`, options);
  },

  // not being used
  cancelledSubscription: async (_: any, { id, getToken }: { id: string; getToken: () => any }) => {
    const token = await getToken();
    const options = {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('CancelledSubscription', `subscriptions/${id}`, options);
  },

  cancelledSubscriptionV2: async (_: any, { areaId, getToken }: { areaId: string; getToken: () => any }) => {
    const token = await getToken();
    const options = {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('CancelledSubscriptionV2', `subscriptionsV2/${areaId}`, options);
  },

  cancelEnterpriseApiSubscription: async (
    _: any,
    { subscriptionId, getToken }: { subscriptionId: string; getToken: () => any },
  ) => {
    const token = await getToken();
    const body = {
      action: 'cancel',
    };

    const options = {
      method: 'delete',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('CancelEnterpriseApiSubscription', `subscriptions/api/${subscriptionId}`, options);
  },

  restartEnterpriseApiSubscription: async (
    _: any,
    { subscriptionId, getToken }: { subscriptionId: string; getToken: () => any },
  ) => {
    const token = await getToken();
    const body = {
      action: 'restart',
    };

    const options = {
      method: 'put',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    return query('RestartEnterpriseApiSubscription', `subscriptions/api/${subscriptionId}`, options);
  },
};

const resolvers: Resolvers = {
  Query: queries,
  Mutation: mutation,
};

export default resolvers;
