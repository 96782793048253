import gql from 'graphql-tag';

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: ClientInput!) {
    updateClient(input: $input) {
      id
      name
      userDescriptionType
      currency
    }
  }
`;

export const ADD_FOLLOW_AREA = gql`
  mutation AddFollowArea($id: Int!) {
    addFollowArea(input: { id: $id }) {
      created
    }
  }
`;

export const DELETE_FOLLOW_AREA = gql`
  mutation RemoveFollowArea($id: Int!) {
    removeFollowArea(input: { id: $id }) {
      removed
    }
  }
`;

export const ADD_CLIENT_SESSION = `
mutation AddClientSession($sessionKey: String! $event: String!) {
    addClientSession(input: {sessionKey: $sessionKey event: $event}) {
      added
    }
  }
`;
