// https://github.com/microsoft/TypeScript/pull/12253#issuecomment-353494273
export function keys<O extends object>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export const pick = <T, K extends keyof T>(obj: T, props: K[]): T => props
  .filter((prop) => obj[prop] !== undefined)
  .reduce((acc, prop) => ({
    ...acc,
    [prop]: obj[prop],
  }), {} as T);

export const safeJSON = (obj: Error) => {
  try {
    return JSON.stringify(obj);
  } catch (_e) {
    return obj;
  }
};

export const isDifferent = (a: string[], b:string[]) => (
  a.length !== b.length || b.some((item) => !a.includes(item))
);
