import { useContext } from 'react';
import { useTreatments, SplitContext } from '@splitsoftware/splitio-react';
import { ISplitContextValues } from '@splitsoftware/splitio-react/types/types';

type HookProps = {
  featureId: string,
}

export const useSplitio = ({
  featureId,
}: HookProps) => {
  const treatments = useTreatments([featureId]);
  const { isReady, isTimedout }: ISplitContextValues = useContext(SplitContext);

  if (isReady && !isTimedout) {
    const isActive = treatments[featureId].treatment === 'on';

    return {
      isActive,
      isReady,
      isTimedout,
    };
  }

  return {
    isActive: false,
    isReady,
    isTimedout,
  };
};
