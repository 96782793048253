import baseAxios, { AxiosInstance } from 'axios';
import * as axiosCookieJarSupport from 'axios-cookiejar-support';
import { Cookie, CookieJar } from 'tough-cookie';

const domain = process.env.IS_STAGING ? 'https://analytics-staging.alltherooms.com' : process.env.DOMAIN || '';

export const defaultConfig = {
  baseURL: domain,

  headers: {
    Accept: 'application/json',
  },

  // do not reject based on the status code
  validateStatus: () => true,

  // send the cookies
  withCredentials: true,
};

// eslint-disable-next-line @typescript-eslint/unbound-method
export const axios = baseAxios.create(defaultConfig).request;

// TypeScript is still incapable to assert for non-unidefined values
// when filtering.
// https://github.com/Microsoft/TypeScript/issues/16069#issuecomment-565658443
function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

// axios with server-side cookies support
const patchAxiosWithCookies = (instance: AxiosInstance, cookieHeader: string) => {
  axiosCookieJarSupport.wrapper(instance);

  const { baseURL = '' } = instance.defaults;

  const cookieJar = cookieHeader
    .split('; ')
    .map((c) => Cookie.parse(c))
    .filter(isPresent)
    .reduce((jar, cookie) => {
      // The 'Cookie' header sent from a browser has only the basic key=value data
      // of all cookies. This means the server never receives other data like
      // the domain, path, httponly and expiration. Makes no sense to re-create it.
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cookie
      jar.setCookieSync(cookie, baseURL, {
        // setCookie sets this by default, but lets make it explicit
        secure: baseURL.indexOf('https') === 0,
      });
      return jar;
    }, new CookieJar());

  // eslint-disable-next-line no-param-reassign
  instance.defaults.jar = cookieJar;

  return instance;
};

export const getAxios = (cookieHeader?: string, instance?: AxiosInstance) => {
  const axiosInstance = instance || baseAxios.create(defaultConfig);

  if (cookieHeader) {
    patchAxiosWithCookies(axiosInstance, cookieHeader);
  }

  return axiosInstance.request.bind(axiosInstance);
};
