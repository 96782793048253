import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { pick, safeJSON } from '@utils/others';
import { NextPageContext } from 'next';
import { setContext } from '@apollo/client/link/context';
import type { GetToken } from '@clerk/types';

import { resolvers } from './resolvers';
import { typePolicies } from './policies/uiPolicy';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const domain = process.env.IS_STAGING
  ? 'https://analytics-staging.alltherooms.com'
  : process.env.GRAPH_ORIGIN || process.env.DOMAIN || '';

export const baseApolloUri = `${domain}/graphql`;
export const adminApolloUri = `${domain}/admin/graphql/admin`;

function createApolloClient(getToken: (() => string | undefined) | GetToken, options: HttpOptions = {}) {
  const authMiddleware = setContext(async (operation, { headers }) => {
    const token = await getToken();

    if (!token) {
      return {
        headers: {
          ...headers,
        },
      };
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const baseLink = from([
    authMiddleware,
    onError(({ graphQLErrors, networkError, operation: { operationName } }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
          // eslint-disable-next-line no-console
          console.error(`[GraphQL error]: Operation: ${operationName}, ${safeJSON(graphQLError)}`);
        });
      }

      // eslint-disable-next-line no-console
      if (networkError) console.error(`[Network error]: Operation: ${operationName}, ${safeJSON(networkError)}`);
    }),
    new HttpLink({
      uri: baseApolloUri,
      credentials: process.env.GRAPH_ORIGIN ? 'include' : 'same-origin',
      ...options,
    }),
  ]);

  const adminLink = from([
    authMiddleware,
    onError(({ graphQLErrors, networkError, operation: { operationName } }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
          // eslint-disable-next-line no-console
          console.error(`[GraphQL error]: Operation: ${operationName}, ${safeJSON(graphQLError)}`);
        });
      }

      // eslint-disable-next-line no-console
      if (networkError) console.error(`[Network error]: Operation: ${operationName}, ${safeJSON(networkError)}`);
    }),
    new HttpLink({
      uri: adminApolloUri,
      credentials: process.env.GRAPH_ORIGIN ? 'include' : 'same-origin',
      ...options,
    }),
  ]);

  const link = ApolloLink.split(
    (operation) => operation.getContext().clientName === `${domain}/admin/graphql/admin`,
    adminLink,
    baseLink,
  );

  const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development' && process.browser,
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache({
      typePolicies,
    }),
    resolvers,
  });

  return client;
}

function initializeApollo(
  initialState: NormalizedCacheObject | null,
  getToken: (() => string | undefined) | GetToken,
  ctx?: NextPageContext,
) {
  const reqHeaders = ctx?.req?.headers || {};
  const headers = pick(reqHeaders, ['cookie', 'x-forwarded-for']) as Record<string, string>;

  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = createApolloClient(getToken, { headers });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any, getToken: (() => string | undefined) | GetToken) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state, getToken), [state, getToken]);
  return store;
}

export const getApolloClient = () => apolloClient;
